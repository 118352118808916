





import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import Util from '@/lib/util';
import PageRequest from '@/store/entities/page-request';
import ListBase from '@/lib/listbase';
import Analyst from '@/store/entities/catalogs/analyst';

class PageAnalystConcatRequest extends PageRequest {
    analyst: number = null;
}

@Component({
    components: { ModalView },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
        },
        modalShow: function (val) {
            if (!val) {
                this.$emit('input', false);
            } else {
                (this as any).initForm();
            }
        }
    }
})

export default class AnalystForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    analyst: Analyst = new Analyst();
    pagerequest: PageAnalystConcatRequest = new PageAnalystConcatRequest();
    modalShow: boolean = false;
    formBase: boolean = true;
    image: any = null;
    modalSetting: any = {
        entity: "analyst",
        title: 'Analista',
        width: "800px", 
    };
    
    items = [
        this.gItem({ type: 'text', prop: 'code', label: 'Codigo', size: 2, required: true }),
        this.gItem({ type: 'text', prop: 'firstname', label: 'Nombres', size: 5, required: true }),
        this.gItem({ type: 'text', prop: 'lastname', label: 'Apellidos', size: 5 }),
        this.gItem({ type: 'text', prop: 'document', label: 'DNI', size: 2 }),
        this.gItem({ type: 'phone', prop: 'phone', label: 'Telefono', size: 5 }),
        this.gItem({ type: 'email', prop: 'email', label: 'Email', size: 5 }),
        this.gItem({ type: 'active', size: 12 }),
    ]   
    
    async initForm() {   
    }

    async save() {
        this.$emit('save-success');
        this.$emit('input', false);

        this.$store.commit('analyst/setActiveList',[]);
        await this.$store.dispatch({
            type: 'analyst/getAllActive',
            data: { force:true}
        });
    }

    cancel() {
        this.$emit('input', false);
    }

    mounted() {
    }

    created() {
    }
}
