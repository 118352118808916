


















import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ListView from '@/components/helper/list-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import AnalystForm from './analyst-form.vue';

class PageAnalystRequest extends PageRequest {
    code: string = '';
    search: string = '';
}

@Component({
    components: { ListView, AnalystForm, FormBase }
})
export default class Analysts extends ListBase {
    entity: string = 'analyst';
    pagerequest: PageAnalystRequest = new PageAnalystRequest();
    filterForm: boolean = true;

    filterItems = [
        this.gItem({ type: 'text', prop: 'code', label: 'Buscar por Codigo', hideDetails: true, onchange: this.afterSave, size: 4 }),
        this.gItem({ type: 'text', prop: 'search', label: 'Buscar por Nombres o Apellido', hideDetails: true, onchange: this.afterSave, size: 8 }),
    ]

    columns = [
        this.gCol('code', 'Codigo', 'text'),
        this.gCol('fullname', 'Nombre de Analista', 'text'),
        this.gCol('document', 'DNI', 'text'),
        this.gCol('email', 'Email', 'text'),
        this.gCol('active', 'Habilitado', 'text'),
        this.defaultActions('edit')
    ]

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    }
    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }     
    

    created() {
        
    }
}